













import {Vue, Component, Prop, Emit} from "vue-property-decorator";

@Component
export default class el_pub_ops extends Vue {
    @Prop() pubOps!: Array<any>;
    @Prop({default: false}) exportLoading?: boolean;
    @Emit()
    add() {

    }
    @Emit('do-import')
    doImport() {

    }
    @Emit('do-export')
    doExport() {

    }
}

